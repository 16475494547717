import React from "react";
import { Root } from "react-static";
import styled from "styled-components";
//
import Meta from "containers/meta";
//
import Menu from "containers/menu";
//
export default ({ children, ...rest }) => (
  <Root>
    <Meta />
    <Menu {...rest} />
    <Wrap>{children}</Wrap>
  </Root>
);
//
const Wrap = styled.div`
  padding-top: 3rem;
  padding-top: calc(3rem + 2px);

  @media (min-width: 769px) {
    padding-left: 240px;
    padding-top: 0;
  }
`;
